import { Button } from '@/components';
import { currentOrder } from '@/orderStore';
import { useStore } from '@nanostores/react';
import { formatCurrency } from '@packages/core';

export const ViewOrderButton = () => {
  const order = useStore(currentOrder);

  if (!order || order.orderItems.length === 0) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-center bg-slate-50/90 px-4 py-4 text-sm font-semibold text-slate-900 ring-1 ring-slate-900/10 backdrop-blur-sm dark:bg-slate-700/90 dark:text-slate-200 dark:ring-black/10">
      <Button
        href="/checkout"
        className={'sm:text-md animate-highlight justify-between font-normal sm:w-80'}
        id={'btn-view-order'}
      >
        <span>{`View order (${order.orderItems.length} item${
          order.orderItems.length > 1 ? 's' : ''
        })`}</span>
        <span className={'ml-4'}>{formatCurrency(order.orderItemsTotal)}</span>
      </Button>
    </div>
  );
};
